import { useEffect, useState } from "react";
import "./App.css";
import AnimatedCursor from "react-animated-cursor";
import Header from "./components/header/Header.jsx"; // Add .jsx extension
import Home from "./components/home/Home.jsx"; // Add .jsx extension
import About from "./components/about/About.jsx"; // Add .jsx extension
import Skills from "./components/skills/Skills.jsx"; // Add .jsx extension
import Services from "./components/services/Services.jsx"; // Add .jsx extension
import Qualification from "./components/qualification/Qualification.jsx"; // Add .jsx extension
import Work from "./components/work/Work.jsx"; // Add .jsx extension
import Certification from "./components/certifications/Certification.jsx"; // Add .jsx extension
import Contact from "./components/contact/Contact.jsx"; // Add .jsx extension
import Footer from "./components/footer/Footer.jsx"; // Add .jsx extension
import ScrollUp from "./components/scrollup/ScrollUp.jsx"; // Add .jsx extension

// icons
import "boxicons/css/boxicons.min.css";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  useEffect(() => {
    // You can use a class on the body or another top-level element
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  return (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={8}
        color="000, 000, 000"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        showSystemCursor={true}
      />
      <Header setIsDarkMode={setIsDarkMode} />
      <main className="main">
        <Home />
        <About />
        <Skills />
        <Services />
        <Qualification />
        <Work />

        <Certification />
        <Contact />
      </main>
      <Footer />
      <ScrollUp />
    </>
  );
}

export default App;
