import React from "react";
import "./certifications.css";
import { Data } from "./Data";
import { Fade } from "react-awesome-reveal";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper/modules";

const Certification = () => {
  return (
    <section className="testimonial container section" id="testimonials">
      <Fade direction="down">
        <h2 className="section_title">Certifications</h2>
        <span className="section_subtitle"></span>
      </Fade>
      <Fade direction="right" duration={2000}>
        <Swiper
          className="testimonial_container"
          loop={true}
          grabCursor={true}
          spaceBetween={24}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 48,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
          }}
          modules={[Pagination]}>
          {Data.map(({ id, image, title, description }) => {
            return (
              <SwiperSlide className="testimonial_card" key={id}>
                <img src={image} alt="" className="testimonial_img" />
                <h3 className="testimonial_name">{title}</h3>
                <p className="testimonial_description">{description}</p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Fade>
    </section>
  );
};

export default Certification;
