

import image9 from "../../assets/c30.png";//cloud
import image10 from "../../assets/c31.png";//oper
import image12 from "../../assets/o1.jpg";
import image13 from "../../assets/o2.jpg";
import image50 from "../../assets/r2.png";
import image51 from "../../assets/r1.png";
import image54 from "../../assets/o54.jpg";
import image56 from "../../assets/o56.jpg";

// import image11 from "../../assets/c32.png";//link
// import image1 from "../../assets/c2.png";//sql
// import image2 from "../../assets/c3.png";//java
// import image3 from "../../assets/c4.png";//word
// import image4 from "../../assets/c5.jpeg";//mich
// import image5 from "../../assets/c6.png";//free
// import image6 from "../../assets/c11.png";//meta
// import image7 from "../../assets/c12.png";//jsp
// import image8 from "../../assets/c13.png";//core
// import image57 from "../../assets/o57.jpg";
// import image58 from "../../assets/o58.png";
// import image59 from "../../assets/o59.png";
// import image60 from "../../assets/o60.png";
// import image55 from "../../assets/o55.jpg";
// import image52 from "../../assets/o52.jpg";
// import image53 from "../../assets/o53.jpg";

export const Data = [
  {
    id: 7,
    image: image13,
    description : "Oracle Certified Professional, Java SE 8 Programmer",
    title: "Oracle",
  },
  {
    id: 7,
    image: image12,
    description : "Oracle Certified Associate, Java SE 8 Programmer",
    title : "Oracle",
  },
  {
    id: 11,
    image: image54,
    title: "Oracle",
    description: "Oracle Cloud Infrastructure 2023 Certified Foundations Associate",
  },
 {
    id: 2,
    image: image9,
    title: "AWS Academy",
    description: "Cloud Foundation",
  },
  {
    id: 10,
    image: image10,
    title: "AWS Academy",
    description: "AWS Academy Engineering Operations Technicians",
  },
  {
    id: 11,
    image: image50,
    title: "O'Reilly",
    description: "PL/SQL Programming",
  },
  {
    id: 11,
    image: image51,
    title: "O'Reilly",
    description: "Oracle Databases",
  }, 
  {
    id: 11,
    image: image56,
    title: "Liquibase",
    description: "Liquibase Certified PractitionerLiquibase Certified Practitioner",
  },
  // {
  //   id: 11,
  //   image: image53,
  //   title: "Oracle",
  //   description: "oci secure code training",
  // },
  // {
  //   id: 11,
  //   image: image52,
  //   title: "Oracle",
  //   description: "CI/CD for Oracle Database using Sqlcl and liquibase",
  // },
  // {
  //   id: 11,
  //   image: image55,
  //   title: "Liquibase",
  //   description: "Fundamentals of Liquibase",
  // },
 
  // {
  //   id: 11,
  //   image: image57,
  //   title: "Project Management Institute",
  //   description: "Power BI Data Methods",
  // },
  // {
  //   id: 11,
  //   image: image58,
  //   title: "CompTIA",
  //   description: "Learning Linux Command Line",
  // },
  // {
  //   id: 11,
  //   image: image59,
  //   title: "GIT",
  //   description: "Career Essentials in GitHub",
  // },
  // {
    
  //   id: 11,
  //   image: image60,
  //   title: "Docker",
  //   description: "Docker Foundations Professional Certificate",
  // },
  // {
  //   id: 11,
  //   image: image11,
  //   title: "LinkedIn Learning",
  //   description: "Devenir développeur / développeuse Java",
  // },
  // {
  //   id: 7,
  //   image: image6,
  //   title: "Meta",
  //   description: "React Basics",
  // },
  
  
  // {
  //   id: 8,
  //   image: image7,
  //   title: "LearnQuest",
  //   description: "Java Servlet Pages (JSPs)",
  // }, {
  //   id: 1,
  //   image: image4,
  //   title: "University of Michigan",
  //   description: "Python Data Structures",
  // },
  // {
  //   id: 3,
  //   image: image1,
  //   title: "OpenClassrooms",
  //   description: "Concevez votre site web avec PHP et MySQL",
  // },
  // {
  //   id: 4,
  //   image: image2,
  //   title: "OpenClassrooms",
  //   description: "Apprenez à programmer avec JavaScript",
  // },{
  //   id: 6,
  //   image: image5,
  //   title: "FreeCodeCamp",
  //   description: "Data Analysis with Python",
  // },
  // {
  //   id: 5,
  //   image: image3,
  //   title: "OpenClassrooms",
  //   description: "Créez un site moderne et professionnel avec WordPress 5",
  // },
  // {
  //   id: 9,
  //   image: image8,
  //   title: "LearnQuest",
  //   description: "Spring - Ecosystem and Core",
  // },
  
];